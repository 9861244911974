import {Link} from "react-router-dom";
import './main.css'
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";



export default function Main() {
    const hasWindow = typeof window !== 'undefined';
    const height = hasWindow ? window.innerHeight : null;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Home Page'
      }
    });
    return (
        <div>
            <Header/>
            <div className="content" min-height={height}>
                <div className="mainContainer">
                    <p className="productsLink"><Link className="nav-link" to={{pathname: '/products', fromDashboard: false}}>Our
                        Products</Link></p>
                </div>
            </div>
            <Footer/>
        </div>

    )
}