import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';

export default function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(true);

    const handleToggleMenu = () => setToggleMenu(!toggleMenu);
    return (<nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded={!toggleMenu} aria-label="Toggle navigation"
                        onClick={handleToggleMenu}>
                    <span className="navbar-toggler-icon"/>
                </button>
                {/*<div className="navbar-nav">*/}
                <div className={`${toggleMenu ? 'navbar-nav collapse' : ''} navbar-nav navbar-collapse`}>
                    <p className="navbar-navlink"><NavLink className="nav-link" to={{pathname: `/`, fromDashboard: false}}>Main Page</NavLink></p>

                    <p className="navbar-navlink"><NavLink className="nav-link"
                                to={{pathname: `/products`, fromDashboard: false}}>Products</NavLink></p>
                    <p className="navbar-navlink"><NavLink className="nav-link" to={{pathname: `/legal-information`, fromDashboard: false}}>Legal
                        Information</NavLink></p>
                </div>
                {/*</div>*/}
            </div>
        </nav>);
}