import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/navbar/Navbar";
import {HashRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/main/Main";
import Products from "./pages/products/Products";
import LegalInformation from "./pages/legalInformation/LegalInformation";
import PersonalInformation from "./pages/pesonalInformation/PersonalInformation";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/termsConditions/TermsConditions";
import ContactUs from "./pages/contactUs/ContactUs";
import TermsLocations from "./pages/termsLocation/TermsLocations";
// gtm.js
// App.js
import TagManager from 'react-gtm-module'



function App() {
    const tagManagerArgs = {
      gtmId: 'G-DJBBZ7SHCW'
    }
    TagManager.initialize(tagManagerArgs)
    // const isMobileDevice = useMediaQuery({
    //     query: "(min-device-width: 480px)",
    // });
    //
    // const isTabletDevice = useMediaQuery({
    //     query: "(min-device-width: 768px)",
    // });
    //
    // const isLaptop = useMediaQuery({
    //     query: "(min-device-width: 1024px)",
    // });
    //
    // const isDesktop = useMediaQuery({
    //     query: "(min-device-width: 1200px)",
    // });
    //
    // const isBigScreen = useMediaQuery({
    //     query: "(min-device-width: 1201px )",
    // });
    return (
        <div className="App">
            <HashRouter>
                <Navbar/>

                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/products" element={<Products/>}/>
                    <Route path="/legal-information" element={<LegalInformation/>}/>
                    <Route path="/agreement/personal-information-collection" element={<PersonalInformation/>}/>
                    <Route path="/agreement/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/agreement/terms-and-conditions" element={<TermsConditions/>}/>
                    <Route path="/agreement/terms-and-conditions-of-location-based-services"
                           element={<TermsLocations/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
