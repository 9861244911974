import './header.css'
import {useEffect, useState} from "react";
import axios from "axios";

export default function Header() {
    const [category, setCategory] = useState({})
    const [posts, setPosts] = useState([])
    const id = 1

    useEffect(() => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}category/${id}`
        }).then(response => {
            setCategory(response.data)
            setPosts(response.data.posts)
        })
    }, [id])

    const idd = category.id
    if (idd === 1) {
        return (
            <header className="dr-header">
                {posts.map(post => (
                    <div key={post.id}>
                        <p className="company-name">{post.title}</p>
                        <p className="contact-info">{post.address}</p>
                    </div>
                ))}

            </header>
        );
    }
}