import ASB from '../../images/app_store_badge.png'
import GPSB from '../../images/google-play-badge.png'
import AQR from '../../images/qrcode_all_in_one.png'
import '../main/main.css'
import '../products/products.css'
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import BlurryLoadingImage from '../products/BlurryImage';

export default function Products() {
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : null;
    const badgeWidth = width / 2.5;
    const qrWidth = width / 3.3;
    function handleAndroidClick (e) {
        window.dataLayer.push({
          event: 'event',
          eventProps: {
              category: 'applink',
              action: 'click',
              label: 'android',
              value: 'https://play.google.com/store/apps/details?id=com.hotcakesolutions.dkb&pli=1'
          }
        });
      };

    function handleIOSClick (e) {
        window.dataLayer.push({
        event: 'event',
        eventProps: {
          category: 'applink',
          action: 'click',
          label: 'ios',
          value: 'https://apps.apple.com/kr/app/drinking-buddy/id1463317609'
        }
        });
    };
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Products Page'
      }
    });
    return (
        <div>
            <Header/>
            <div className="dkbContainer">
                <div className="productsContainer">
                    <BlurryLoadingImage />

                    <div className="links">
                        <p className="qrLinks">
                                <img src={AQR} alt="Play Store QR" className="qr" width={qrWidth} height={qrWidth}/>
                            </p>
                        <div className="storeLinks">
                            <p className="imglinks">
                                <a href="https://apps.apple.com/kr/app/drinking-buddy/id1463317609" onClick={handleIOSClick}>
                                    <img src={ASB} alt="App Store Badge" className="badgeStore" width={badgeWidth} />
                                </a>
                            </p>
                            <p className="imglinks">
                                <a href="https://play.google.com/store/apps/details?id=com.hotcakesolutions.dkb&pli=1"
                                onClick={handleAndroidClick}>
                                    <img src={GPSB} alt="Google Play Store Badge" className="badgeStore" width={badgeWidth} />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}