import React, { useEffect, useState } from 'react';
import BANNER from '../../images/Homepage_Product_Introduction.svg'
import PREVIEW from '../../images/Banner_preview.png'

const BlurryLoadingImage = ({
  bgColor = 'transparent'
}) => {
  const [currentImage, setCurrentImage] = useState(PREVIEW);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(BANNER);
  }, []);

  return (
    <div className="" style={{ overflow: 'hidden' }}>
      <img
        style={{
          filter: `${loading ? 'blur(20px)' : ''}`,
          transition: '1s filter linear',
          width: '100%',
          background: bgColor,
        }}
        src={currentImage}
        alt="intro"
        className="intro"
      />
    </div>
  );
};

export default BlurryLoadingImage;