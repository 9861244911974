import {useEffect, useState} from "react";
import axios from "axios";
import './legalinformation.css'
import Footer from "../../components/footer/Footer";

export default function LegalInformation() {
    const [category, setCategory] = useState({})
    const [posts, setPosts] = useState([])
    const id = 3

    useEffect(() => {
        axios({
            method: "GET", url: `${process.env.REACT_APP_API_URL}category/${id}`
        }).then(response => {
            setCategory(response.data)
            setPosts(response.data.posts)
            console.log(response.data)
        })
    }, [id])

    const idd = category.id
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Legal Page'
      }
    });


    if (idd === 3) {

        return (
            <div>
                <div className="content">
                    <div className="legal-information-container">
                        {
                            posts.map(p => (
                                <div key={p.id}>

                                    <div className="legal-information-title">
                                        <h1 className="title"> {p.title}</h1>
                                    </div>

                                    <div className="legal-information-text-container">
                                        <p className="legal-information-text"> {p.content}</p>
                                    </div>

                                </div>))
                        }
                    </div>
                </div>
                <Footer/>
            </div>
        )
    } else return (<div>
    </div>)
}