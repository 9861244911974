import axios from "axios";
import parse from 'html-react-parser';
import {useEffect, useState} from "react";
import './privacypolicy.css'

export default function PrivacyPolicy() {
    const url = process.env.REACT_APP_API_URL + "agreement/PRIVACY_POLICY"
    const [content, setContent] = useState({})


    useEffect(() => {
        axios({
            method: "GET", url: url
        }).then(response => {
            setContent(response.data);
        })
    })

    if (typeof(content.content) == 'string'){
        return (
            <div>
                <div className="content">
                    <div className="agreement">
                        {parse(content.content)}
                    </div>
                </div>
            </div>
            );
    }
}