import './footer.css'
import {Link} from "react-router-dom";

export default function Footer() {
    return (<footer className="dr-footer">
        <p className="copyright footer-text-margin">Copyright 2023 Hot Cake Solutions Co,. Ltd.</p>
        <p className="footer-text-margin"><Link to={{pathname: '/legal-information', fromDashboard: false}}
                                                className="nav-link">Legal Disclaimer</Link></p>
        <p className="footer-text-margin"><Link to={{pathname: '/contact-us', fromDashboard: false}}
                                                className="nav-link">Contact us</Link></p>
    </footer>);
}