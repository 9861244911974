import './contactus.css'
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

export default function ContactUs() {
    return (
        <div>
            <Header/>
            <div className="content">
                <div className="contact-us-container">
                    <p className="contact-us-title">Contact Us</p>
                    <p className="contact-us-email">support@drinkingbuddy.me</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}